import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Navbar from "./components/Navbar"
import Home from "./pages/Home";
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Footer from './components/Footer';
import Gallery from './pages/Gallery';
import Services from './pages/Services';
import WhatsAppWidget from './pages/WhatsAppWidget';
const App: React.FC = () => {
  return (
    <Router>
      <div style={{ paddingBottom: '60px', minHeight: '100vh', position: 'relative' }}> 
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/services" element={<Services />} />
        </Routes>
      </div>
      <WhatsAppWidget/>
      <Footer />
    </Router>
  );
};


export default App;
