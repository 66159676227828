import React from 'react';
import { Typography, Button } from '@mui/material';
import contactimg from "../assets/contactimg.webp";

const ContactUs: React.FC = () => {
  const openLargeMap = () => {
    window.open('https://www.openstreetmap.org/export/embed.html?bbox=-180,-90,180,90', '_blank');
  };

  return (
    <div style={{ textAlign: "center", marginBottom: "20px", marginTop: "40px" }}>
      <div style={{
        backgroundImage: `url(${contactimg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: "100%",
        height: "60vh",
        color: "white",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <Typography variant="h3" style={{ fontFamily: "Lato",fontWeight:600,fontSize:"48px" }}>Contact Us</Typography>
      </div>

      <div>
        <div style={{display:"flex",flexDirection:"row",justifyContent:"center",fontSize:"26px"}}>
          <h2 style={{fontFamily:"Lato",fontWeight:700}}>Get In </h2> <h2 style={{fontFamily:"Lato",fontWeight:700,color:"rgb(36,52,114)"}}> Touch</h2></div>
        
        <div style={{ alignContent: "left", textAlign: "left" }}>
          <iframe
            width="100%"
            height="450"
            style={{ border: 0 }}
            loading="lazy"
            allowFullScreen
            src="https://www.openstreetmap.org/export/embed.html?bbox=-180,-90,180,90"
          />
          <Button onClick={openLargeMap}>View Larger Map</Button>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
