import React, { useState } from 'react';
import { Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

interface GalleryModalProps {
  images: string[];
  selectedImage: string;
  onClose: () => void;
}

const GalleryModal: React.FC<GalleryModalProps> = ({ images, selectedImage, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(images.indexOf(selectedImage));
  const [zoomFactor, setZoomFactor] = useState(1);
  const [zoomIn, setZoomIn] = useState(true);

  const handleNextImage = () => {
    const nextIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(nextIndex);
  };

  const handlePreviousImage = () => {
    const previousIndex = (currentImageIndex - 1 + images.length) % images.length;
    setCurrentImageIndex(previousIndex);
  };

  const handleZoom = () => {
    if (zoomIn) {
      setZoomFactor(zoomFactor + 0.8);
    } else {
      setZoomFactor(zoomFactor - 0.8);
    }
    setZoomIn(!zoomIn);
  };

  const isFirstImage = currentImageIndex === 0;
  const isLastImage = currentImageIndex === images.length - 1;

  return (
    <Modal open={true} onClose={onClose}>
      <>
        <div style={{ position: "relative", textAlign: "center", height: "60%", marginTop: "20vh" }}>
          <img src={images[currentImageIndex]} alt="Selected Image" style={{ maxWidth: "100%", maxHeight: "100%", transform: `scale(${zoomFactor})`, transition: "transform 0.3s" }} />
          <div style={{ position: "absolute", top: "50%", left: 0, transform: "translateY(-50%)" }}>
            {!isFirstImage && <IconButton onClick={handlePreviousImage} style={{ color: "#FFFFFF", backgroundColor:"#000000",borderRadius: '50%',width: '40px',height:"40px" }}><NavigateBeforeIcon /></IconButton>}
          </div>
          <div style={{ position: "absolute", top: "50%", right: 0, transform: "translateY(-50%)" }}>
            {!isLastImage && <IconButton onClick={handleNextImage} style={{ color: "#FFFFFF", backgroundColor:"#000000",borderRadius: '50%',width: '40px',height:"40px" }}><NavigateNextIcon /></IconButton>}
          </div>
        </div>
        <div style={{ position: "absolute", top: 60, right: 10, zIndex: 999 }}>
          <IconButton onClick={handleZoom} style={{ color: "#FFFFFF", marginRight: 10 }}>{zoomIn ? <ZoomInIcon /> : <ZoomOutIcon />}</IconButton>
          <IconButton onClick={onClose} style={{ color: "#FFFFFF" }}><CloseIcon /></IconButton>
        </div>
      </>
    </Modal>
  );
};

export default GalleryModal;
