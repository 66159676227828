import "../styles/service.css";
import React, { useState } from 'react';
import { Typography, Button, Card } from '@mui/material'; // Importing Card component
import roup from "../assets/roup.jpg";
import road1 from "../assets/road1.jpg";

const servicedata: Service[] = [
  {
    title: "Road Construction",
    description: "Expert construction of durable and reliable roads, ensuring safety and efficiency for all users."
  },
  {
    title: "Pavement Markings and Signage",
    description: "Precision application of pavement markings and signage to enhance traffic flow and safety on roads."
  },
  {
    title: "Site Preparation",
    description: "Thorough site preparation services, including clearing, grading, and drainage installation, to lay the groundwork for successful road construction projects."
  },
  {
    title: "Infrastructure Development",
    description: "Comprehensive infrastructure development solutions, integrating roads with surrounding structures and utilities for seamless connectivity."
  },
  {
    title: "Consulting and Project Management",
    description: "Professional consulting and project management services to ensure efficient planning, execution, and delivery of road construction projects on time and within budget."
  }
];

interface Service {
  title: string;
  description: string;
}

const Services: React.FC = () => {
    const [startIndex, setStartIndex] = useState(0);
  
    const handlePrevClick = () => {
      if (startIndex > 0) {
        setStartIndex(startIndex - 1);
      }
    };
  
    const handleNextClick = () => {
      if (startIndex + 3 < servicedata.length) {
        setStartIndex(startIndex + 1);
      }
    };
  
    return (
      <div style={{ textAlign: "center", marginBottom: "20px", marginTop: "10%" }}>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h3" style={{ fontFamily: "Lato", fontWeight: 700 }}>Our Services</Typography>
        </div>
        <p>Dedicated to delivering top-tier road construction solutions, ensuring durability and efficiency for every project.</p>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', textAlign: "center", padding: "40px" }}>
          <Button disabled={startIndex === 0} onClick={handlePrevClick} style={{backgroundColor: '#4CAF50',marginRight:"40px",
    borderRadius: '20px',
    color: 'white',
    padding: '10px 20px',
    fontWeight: 'bold',
    textTransform: 'none',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '1px', 
    borderBottomLeftRadius: '1px',
    borderBottomRightRadius: '20px',
   
}}>{"<"}</Button>
          {servicedata.slice(startIndex, startIndex + 3).map((service, index) => (
            <div key={startIndex + index} style={{marginRight: "20px"}}>
              <Card style={{ width: '300px', height: '250px',boxShadow:"0 4px 8px 0 rgba(0,0,0,0.2)",padding:"20px",fontFamily:"Lato" }}>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </Card>
            </div>
          ))}
          <Button disabled={startIndex + 3 >= servicedata.length} onClick={handleNextClick} style={{backgroundColor: '#4CAF50',
    borderRadius: '20px',
    color: 'white',
    padding: '10px 20px',
    fontWeight: 'bold',
    textTransform: 'none',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '1px', 
    borderBottomLeftRadius: '1px',
    borderBottomRightRadius: '20px',marginLeft:"40px",
   
}}>{">"}</Button>
        </div>
      </div>
    );
  };
  
  export default Services;
