import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, Button, CssBaseline, Drawer, IconButton, Toolbar, Typography, useMediaQuery,Tab,Tabs } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import logo from "../assets/logo2.jpeg";
import EmailIcon from '@mui/icons-material/Email';
import CallRoundedIcon from '@mui/icons-material/CallRounded';

interface Props {
    window?: () => Window;
}
const navItems = ['Home', 'About Us', 'Gallery', 'Contact Us'];
export default function DrawerAppBar(props: Props) {
    const navigate = useNavigate();
    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('');
const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
const isMobile = useMediaQuery('(max-width:600px)');
const handleNavigation = (path: string, item: string) => {
    const formattedPath = path === "/home" ? "/" : path; 
    navigate(formattedPath);
    setActiveTab(item);
    setMobileOpen(false);
};
const backgroundImageStyle = isMobile
        ? "linear-gradient(115deg, #000000 80.5%, #FFFFFF 5%)"
        : "linear-gradient(115deg, #000000 20.5%, #FFFFFF 5%)";
const backgoundiconstyle =isMobile
? "linear-gradient(115deg, #D9D9D9 37.5%, #055A98 5%)"
: "linear-gradient(115deg, #D9D9D9 17.5%, #055A98 5%)";
    const container = window !== undefined ? () => window().document.body : undefined;
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const item = navItems[newValue];
        handleNavigation(`/${item.toLowerCase().replace(' ', '-')}`, item);
    };
    
return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
             
      <Toolbar style={{
    backgroundColor: 'transparent',
    backgroundImage: backgoundiconstyle,
    transition: 'background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s',
    borderBottom: '1px solid #ccc',
    justifyContent: "space-between",fontFamily: "Lato"
}}>
    <Typography variant="h6" noWrap component="div" sx={{ textAlign: "left", display: "flex" }}>
        <img src={logo} style={{ width: "100%", height: "30px",marginLeft:"20px" }} />
    </Typography>
    <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerToggle}
        sx={{ display: { xs: 'block', sm: 'none' }, color: "#000000" }} 
    >
        <MenuIcon />
    </IconButton>
    <Box sx={{ display: { xs: 'none', sm: 'block' }, justifyContent: "center", flex: 1, textAlign: "center" }}>
    <Tabs
  value={navItems.indexOf(activeTab)}
  onChange={handleChange}
  textColor="secondary"
  aria-label="tabs"
  centered
  sx={{
    '& .MuiTabs-indicator': {
      backgroundColor: '#fff', // Set indicator color to white
    },
  }}
>
            {navItems.map((item) => (
                <Tab
                    key={item}
                    label={item}
                    style={{
                        fontWeight: 500,
                        marginRight: '5px',
                        justifyContent: "center",
                        textAlign: "center",
                        fontFamily: "Lato",
                        alignContent: "center",
                        color:"#FFFFFF"
                    }}
                />
            ))}
        </Tabs>
    </Box>
    <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
        <Box sx={{ display: 'flex', marginRight: "15px" }}>
            <EmailIcon style={{ color: '#FFFFFF' }} />
            {!isMobile && (
                <a href="mailto:info@rasroadworks.com" style={{ textDecoration: 'none', color: '#FFFFFF', marginLeft: '5px' }}>
                    <Typography variant="body2" style={{ fontSize: "16px",fontFamily: "Lato" }}>info@rasroadworks.com</Typography>
                </a>
            )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CallRoundedIcon style={{ color: '#FFFFFF', marginRight: 15 }} />
            <a href="tel:+91 8978507998" style={{ textDecoration: 'none', color: '#FFFFFF' }}>
                <Typography variant="body2" style={{ marginRight: 20, fontSize: "16px",fontFamily: "Lato" }}>+91 96880 90492</Typography>
            </a>
        </Box>
    </Box>
</Toolbar>


            </AppBar>
            <nav >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}

                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { width: 'auto', marginTop: "50px" },
                    }}
                >
                    <Toolbar />
                    {navItems.map((item) => (
                        <Button
                            key={item}
                            fullWidth
                            color="inherit"
                            onClick={() => handleNavigation(`/${item.toLowerCase().replace(' ', '-')}`, item)}
                            style={{
                                // backgroundColor: activeTab === item ? '#000000' : 'transparent',
                                color: activeTab === item ? '#000000' : '#000000',
                                marginBottom: '5px',fontFamily: "Lato"
                            }}>
                            {item}
                        </Button>
                    ))}
                </Drawer>
            </nav>
        </Box>
    );
}