import React from 'react';
import { Container, Typography, Box,IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from "../assets/logo2.jpeg";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
const Footer: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer style={{ 
      left: 0, 
      bottom: 0, 
      width: '100%', 
      backgroundColor: "#000",
      color: '#fff',
      fontFamily: "Lato"
    }}> 
      <Container sx={{ display: 'flex', flexDirection: 'column', padding: '20px' }}> 
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <Box sx={{ flex: "1 1 20%", minWidth: "100px", marginRight: "30px" }}>
            <img src={logo} style={{width:"30%"}} alt="Logo"/>
            <p style={{fontWeight:400,fontSize:"16px",fontFamily: "Lato"}}>RAS Roadworks is your trusted partner in road construction and maintenance.
             We are committed to delivering quality and safety in every project we undertake. Our emphasis on trustworthiness,
              quality, and safety ensures that your infrastructure needs are met with excellence, every time.
            </p>
          </Box>
          <Box sx={{ flex: '1 1 auto', minWidth: '200px', marginRight: 2, marginBottom: 4 }}>
            <Typography variant="h6" style={{fontWeight:600,fontSize:"21px",color:"#D3E3FC",fontFamily: "Lato"}}>Quick Links</Typography>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }} onClick={scrollToTop}>
              <Typography style={{fontWeight:400,fontSize:"16px",fontFamily: "Lato"}}>Home</Typography>
            </Link>
            <Link to="/about-us" style={{ textDecoration: 'none', color: 'inherit' }} onClick={scrollToTop}>
              <Typography style={{fontWeight:400,fontSize:"16px",fontFamily: "Lato"}}>About Us</Typography>
            </Link>
            <Link to="/gallery" style={{ textDecoration: 'none', color: 'inherit' }} onClick={scrollToTop}>
              <Typography style={{fontWeight:400,fontSize:"16px",fontFamily: "Lato"}}>Gallery</Typography>
            </Link>
            <Link to="/contact-us" style={{ textDecoration: 'none', color: 'inherit' }} onClick={scrollToTop}>
              <Typography style={{fontWeight:400,fontSize:"16px",fontFamily: "Lato"}}>Contact Us</Typography>
            </Link>
          </Box>
          <Box sx={{ flex: '1 1 auto', minWidth: '200px', marginRight: 2, marginBottom: 4 }}>
            <Typography variant="h6"  style={{fontWeight:600,fontSize:"21px",color:"#D3E3FC",fontFamily: "Lato"}}>Our Services</Typography>
            <Typography style={{fontWeight:400,fontSize:"16px",fontFamily: "Lato"}}>Road Construction</Typography>
            <Typography style={{fontWeight:400,fontSize:"16px",fontFamily: "Lato"}}>Pavement Markings and Signage</Typography>
            <Typography style={{fontWeight:400,fontSize:"16px",fontFamily: "Lato"}}>Site Preparation</Typography>
            <Typography style={{fontWeight:400,fontSize:"16px",fontFamily: "Lato"}}>Infrastructure Development</Typography>
            <Typography style={{fontWeight:400,fontSize:"16px",fontFamily: "Lato"}}>Consulting and Project Management</Typography>
          </Box>
          <Box sx={{ flex: '1 1 auto', minWidth: '200px', marginRight: 2, marginBottom: 4 }}>
            <Typography variant="h6"  style={{fontWeight:600,fontSize:"21px",color:"#D3E3FC",fontFamily: "Lato"}}>Get In Touch</Typography>
            <a href="tel:+91 8978507998" style={{ textDecoration: 'none', color: '#FFFFFF' }}> <Typography  style={{fontWeight:400,fontSize:"16px",
            fontFamily: "Lato"}}>+91 96880 90492</Typography></a>
            <Typography style={{fontWeight:400,fontSize:"16px",fontFamily: "Lato"}}>213, AnnaNagar, Venganayakkan Palayam, <br/>Punjai Puliampatti - 638459</Typography>
            <a href="mailto:info@rasroadworks.com" style={{ textDecoration: 'none', color: '#000000', marginLeft: '5px' }}> <Typography style={{fontWeight:400,fontSize:"16px",
            fontFamily: "Lato"}}>info@rasroadworks.com</Typography>
              </a>
            <Typography style={{fontWeight:600,fontSize:"21px",color:"#D3E3FC",fontFamily: "Lato",}}>Follow Us</Typography>
           
                        <IconButton href="https://facebook.com" >
                            <FacebookIcon style={{ color: "#FFFFFF" }} />
                        </IconButton>
                        <IconButton href="https://twitter.com" >
                            <TwitterIcon style={{ color: "#FFFFFF" }} />
                        </IconButton>
                        <IconButton href="https://instagram.com" >
                            <InstagramIcon style={{ color: "#FFFFFF" }} />
                        </IconButton>
                   

          </Box>
       
        </Box>
      </Container>
      <div style={{ textAlign: "center", paddingLeft: '20px' }}>
        <p style={{fontFamily: "Lato"}}>© Copyrights 2024 | All Rights Reserved | Design & developed by Pheonix Solutions</p>
      </div>
    </footer>
  );
};

export default Footer;
